function pushDataLayerGoogleTag(data) {
  if (!window.gtag) return;

  if (data.event) {
    const event = data.event;
    const gtagData = { ...data };
    delete gtagData.event;

    window.gtag('event', event, gtagData);
  } else if (window.__googleTagId && data.sl_user_id) {
    window.gtag('config', window.__googleTagId, {
      user_id: data.sl_user_id,
    });
  } else {
    console.warn('Data not pushed to Google tag:', data);
  }
}

function pushDataLayerGoogleTagManager(data) {
  if (!window.dataLayer) return;

  window.dataLayer.push(data);
}

function pushDataLayer(data) {
  if (window.__useGoogleTag) {
    pushDataLayerGoogleTag(data);
  } else if (window.__useGoogleTagManager) {
    pushDataLayerGoogleTagManager(data);
  } else {
    console.warn('Data not pushed to Google:', data);
  }
}

export default pushDataLayer;
