import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';

function createCookie(name, value, expireMinutes, path) {
  let cookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;
  let expires = new Date();

  if (isVariableDefinedNotNull(expireMinutes)) {
    expires = new Date(expires.getTime() + expireMinutes * 60000);
  } else {
    expires.setFullYear(expires.getFullYear() + 10);
  }

  cookie += `; expires=${expires.toUTCString()}`;

  if (isVariableDefinedNotNull(path)) {
    cookie += `; path=${path}`;
  } else {
    cookie += '; path=/';
  }

  document.cookie = cookie;
}

export default createCookie;
